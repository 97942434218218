.footer{
    width: 100%; 
    height: auto; 
    margin: auto;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    background-color: #F2F0F0;
    position: fixed;
    bottom: 0;
    left: 0;
}

.footer-left-panel {
    margin-left: 70px;
    width: auto;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px; 
    display: flex;
}

.footer-left-panel-about-n7 {
    color: #08142B;
    font-size: 16px; 
    font-family: "Roboto"; 
    font-weight: 300;
    word-wrap: break-word
}

.footer-left-panel-contact {
    color: #08142B;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 300; 
    word-wrap: break-word;
}

a#about-n7-link {
    text-decoration: none;
    color: unset;
}

a#contact-link {
    text-decoration: none;
    color: unset;
}

.footer-right-panel {
    margin-right: 70px;
    flex: 1 1 0;
    text-align: right;
    color: #08142B; 
    font-size: 16px; 
    font-family: "Roboto";
    font-weight: 300;
    word-wrap: break-word;
}