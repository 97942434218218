.login-navbar {
    width: 100%
}

.login-navbar-right-panel {
    width: 100%; 
    height: 36px; 
    position: relative;
}

.login-navbar-language-selector{
    width: 36px;
    height: 36px; 
    right: 0;
    top: 0;
    position: absolute;
}

.navbar {
    width: 100%;
    height: auto;
    padding-top: 27px;
    padding-bottom: 27px; 
    padding-left: 125px;
    padding-right: 70px; 
    background: #ffffff; 
    box-shadow: 0px 3px 10px rgba(8, 20, 43, 0.16); 
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    justify-content: space-between; 
    align-items: center; 
    display: inline-flex;
    margin-bottom: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.navbar-left-panel {
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.navbar-co-logo {
    padding-left: 8.50px; 
    padding-right: 8.50px; 
    justify-content: center; 
    align-items: center; 
    display: flex;
    margin-right: 76px;
}

.navbar-links {
    justify-content: flex-start;
    align-items: flex-start; 
    display: flex;
    gap: 76px;
}

.navbar-link {
    font-size: 20px;
    font-family: "Roboto"; 
    font-weight: 700; 
    word-wrap: break-word;
}

.navbar-right-panel {
    width: 104px; 
    height: 36px; 
    position: relative;
}

.navbar-language-selector{
    width: 36px;
    height: 36px; 
    left: 0;
    top: 0;
    position: absolute;
}

.navbar-logout {
    width: 36px;
    height: 36px; 
    left: 68px;
    top: 0;
    position: absolute;
}

.navbar .navbar-nav .nav-item:first-child {
	margin-top: 0; 
}

.navbar a:not(.btn):not(.dropdown-item) {
    color: unset;
}