body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.modal-dialog {
  top: 25%;
}

div.modal-content {
  width: auto;
  height: auto;
  padding: 24px 26px;
  color: #000000;
  background: #F2F0F0;
  box-shadow: 0px 3px 10px rgba(14, 14, 14, 0.16);
  border-radius: 10px;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 21px;
  display: inline-flex;
}

div.modal-header {
  all: unset;
}

div.modal-title {
  margin: 0;
  align-self: stretch;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  word-wrap: break-word;
}

div.modal-body {
  padding: 0;
}

div.modal-footer {
  all: unset;
}